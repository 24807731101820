// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import {ColorThemePlugin} from '../services/vuestic-ui';
import store from '../store/index';
import router from '../router/index';
import moment from 'moment';
import firebase from 'firebase';
import {VuesticPlugin} from '../services/vuestic-ui/components';
import '../i18n/index';
import YmapPlugin from 'vue-yandex-maps';
import VueClipboard from 'vue-clipboard2';
import axios from 'axios';
import '../components/firebaseInit';

import '../metrics';
import '../registerServiceWorker';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueFormGenerator from 'vue-form-generator';
import 'vue-form-generator/dist/vfg.css';
import VueContext from 'vue-context';
import 'vue-context/dist/css/vue-context.css';

if (process.env.VUE_APP_BUILD_VERSION) {
  // eslint-disable-next-line
  const message = `%c${'Build_information:'}\n %c${'Version'}: %c${VERSION},\n %c${'Timestamp'}: %c${TIMESTAMP},\n %c${'Commit'}: %c${COMMIT}`
  // eslint-disable-next-line
  console.info(
      message,
      'color: blue;', 'color: red;', 'color: blue;', 'color: red;', 'color: blue;', 'color: red;', 'color: blue;',
  );
}
Vue.component('VueContext', VueContext);

Vue.use(VuesticPlugin);
Vue.use(YmapPlugin);
Vue.use(VueClipboard);
Vue.use(VueSweetalert2);
Vue.use(VueFormGenerator);
Vue.use(VueContext);
Vue.use(firebase);


Vue.use(ColorThemePlugin, {
  // override colors here.
});

router.beforeEach((to, from, next) => {
  store.commit('setLoading', true);
  next();
});

router.afterEach((to, from) => {
  store.commit('setLoading', false);
});

let app;
axios.get('/__/firebase/init.json').then(async (response) => {
  firebase.initializeApp(await response.data);
  if (window.location.host.indexOf('local') !== -1) {
    const firebaseHost = 'http://localhost:5001';
    firebase.functions().useFunctionsEmulator(firebaseHost);
    firebase.firestore().useEmulator('localhost', 8081);
    firebase.storage().useEmulator('localhost', 6969);
    firebase.auth().useEmulator('http://localhost:9099');
    window.firebaseURL = firebaseHost + '/nihss-bb52c/us-central1/';
  } else {
    window.firebaseURL = 'https://us-central1-nihss-bb52c.cloudfunctions.net/';
  }
  Vue.prototype.$firebase = firebase;
  Vue.prototype.$db = firebase.firestore();
  Vue.prototype.$moment = moment;
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        user.admin = idTokenResult.claims.admin;
      });
      store.dispatch('setUser', user);
    }

    if (!app) {
      /* eslint-disable no-new */
      new Vue({
        el: '#app',
        router,
        store,
        render: (h) => h(App),
      });
    }
  });
});
