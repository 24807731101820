export const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      showOnProd: true,
      accessLevel: 0,
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      },
    },
    {
      name: 'users', // named route
      displayName: 'Users', // name on the side bar (parent)
      showOnProd: true,
      accessLevel: 0,
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-user',
      },
      children: [
        {
          name: 'adminuser', // named route
          accessLevel: 2,
          displayName: 'Edit Admins ', // name on the sidebar
        },
        {
          name: 'demouser', // named route
          accessLevel: 1,
          displayName: 'Demo Users', // name on the sidebar
        },
        {
          name: 'findusers',
          displayName: 'Find Users',
        },
        {
          name: 'createuser',
          displayName: 'Create User',
        },
        {
          name: 'userProcedure',
          displayName: 'Users Procedure',
        },
        {
          name: 'releaseList',
          displayName: 'Release List',
        },
        {
          name: 'mergeAccounts',
          displayName: 'Merge Accounts',
        },
      ],
    },
    {
      name: 'organizations', // named route
      displayName: 'Organization', // name on the side bar (parent)
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'fa fa-building-o va-icon fa fa-building-o',
      },
      children: [
        {
          name: 'index',
          displayName: 'List Organizations',
        },
        {
          name: 'new',
          displayName: 'Create New',
        },
      ],
    },
    {
      name: 'Quotes',
      displayName: 'Quotes',
      showOnProd: true,
      accessLevel: 2,
      meta: {
        iconClass: 'va-icon fa fa-bookmark',
      },
      children: [ 
        {
          name: 'quoteTable',
          displayName: 'NIHSS+ Quotes',
        },  
      ],
    },
    {
      name: 'publicSide',
      displayName: 'Public Side',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'va-icon fa fa-globe',
      },
      children: [ 
        {
          name: 'unityWebGL',
          displayName: 'NIHSS+ WebGL',
        },
        {
          name: 'translation',
          displayName: 'Multilingual Support',
        },
        {
          name: 'faq',
          displayName: 'FAQ',
        },  
      ],
    },
    {
      name: 'editor',
      displayName: 'Exams & Courses',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'fa fa-certificate',
      },
      disabled: true,
      children: [
        {
          name: 'exam',
          displayName: 'Exams',
        },
        {
          name: 'courseSelection',
          displayName: 'Training Courses',
        },
        {
          name: 'model',
          displayName: '3D Models',
        },
      ],
    },
    {
      name: 'Closed Caption',
      displayName: 'Closed Caption',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'va-icon fa fa-cc',
      },
      children: [
        {
          name: 'closedcaption-new',
          displayName: 'Patient',
        },
        {
          name: 'closedcaption-training',
          displayName: 'Training',
        },
        {
          name: 'closedcaption-update',
          displayName: 'View/Update',
        },
      ],
    },
    {
      name: 'templates',
      displayName: 'Templates',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'va-icon fa fa-file-text',
      },
      children: [
        {
          name: 'email',
          displayName: 'Email Editor',
        },
        {
          name: 'eval',
          displayName: 'Evaluation',
        },
        {
          name: 'greenCards',
          displayName: 'Green Cards',
        },
      ],
    },
    {
      name: 'resources',
      displayName: 'Resources',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'va-icon fa fa-book',
      },
      children: [
        {
          name: 'resources-new',
          displayName: 'Create New',
        },
        {
          name: 'resources-view',
          displayName: 'View Resources',
        },
      ],
    },
    {
      name: 'support-ticket',
      displayName: 'Support Ticket',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'fa fa-ticket',
      },
      children: [
        {
          name: 'nihss-tickets',
          displayName: 'NIHSS+ Helpdesk',
        },
        {
          name: 'evaluation-tickets',
          displayName: 'Evaluations Helpdesk',
        },
        {
          name: 'show-tickets',
          displayName: 'Tickets Not Assigned',
        },
        {
          name: 'custom-replies',
          displayName: 'Custom Replies',
        },
        {
          name: 'custom-categories',
          displayName: 'Custom Categories',
        }
      ],
    },
    {
      name: 'reports',
      displayName: 'Reports',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'ion ion-ios-podium',
      },
      children: [
        {
          name: 'evaluation-statistics',
          displayName: 'Evaluation Statistics',
        },
        {
          name: 'credential-statistics',
          displayName: 'Credential Test Statistics',
        },
        {
          name: 'patient-statistics',
          displayName: 'Patient Statistics',
        },
        {
          name: 'completed-exams',
          displayName: 'Completed Exams',
        },
      ],
    },
    {
      name: 'logs',
      displayName: 'Logs',
      accessLevel: 2,
      showOnProd: true,
      meta: {
        iconClass: 'fa fa-bar-chart',
      },
      children: [
        {
          name: 'historyLog',
          displayName: 'History Logs',
        },
        {
          name: 'reportingLog',
          displayName: 'Reporting Logs',
        },
        {
          name: 'mailLog',
          displayName: 'Mail Log',
        },
      ],
    },
    {
      name: 'settings',
      displayName: 'Settings',
      showOnProd: true,
      accessLevel: 1,
      meta: {
        iconClass: 'va-icon fa fa-cog',
      },
      children: [
        {
          name: 'globalEnvironment',
          displayName: 'Global Environment',
        },
        {
          name: 'credentials',
          displayName: 'Credentials',
        },
      ],
    },
  ].filter((route) => {
    if (process.env.NODE_ENV === 'development') {
      return true;
    } else {
      return route.showOnProd;
    }
  }),
};
